<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Security Analyst
                                <router-link to="/careers/IT-security-analyst/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for an <b>IT Security Analyst</b> with expertise in Security Information Event Management. Your role is to monitor the organization's networks for security breaches and investigate when one occurs. You will likewise implement security systems to prevent cyber attacks and strengthen cybersecurity. The IT Security Analyst should have experience implementing security systems on Mac OS in a remote or work-from-home environment.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Responsibilities
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Primary duty is toMonitor incoming emails of the entire net ensure that the company and customer's digital assets are secure and protected from unauthorized access.</li>
                                            <li><i class="icon-ok"></i>Work and conduct security checks and tests.</li>
                                            <li><i class="icon-ok"></i>Continuously monitors the customers' infrastructure to identify suspicious activity within IT systems and networks.</li>
                                            <li><i class="icon-ok"></i>Respond to cybersecurity incidents.</li>
                                            <li><i class="icon-ok"></i>Proactively investigate suspicious activities, ensuring that potential security incidents are correctly defended, identified, analyzed and investigated.</li>
                                            <li><i class="icon-ok"></i>Review requests for application installation to check if apps are safe and secure.</li>
                                            <li><i class="icon-ok"></i>Perform triage on alerts, understand the extent of the threat, and respond, or if necessary, escalate the incident to higher-tier analysts.</li>
                                            <li><i class="icon-ok"></i>Collects data and context necessary to initiate Level 2 escalation.</li>
                                            <li><i class="icon-ok"></i>Works closely with Level 2 & Level 3 team towards the continuous improvement of the service.</li>
                                            <li><i class="icon-ok"></i>Build process workflows and create process documentations when necessary.</li>
                                            <li><i class="icon-ok"></i>Perform special tasks and projects assigned.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Experience with Security Information Event Management (SIEM) tools such as Crowdstrike, SentinelOne or Sophos, administration of SIEM, system hardening, and Vulnerability Assessments.</li>
                                            <li><i class="icon-ok"></i>Knowledge of threat response techniques, systems, and strategies.</li>
                                            <li><i class="icon-ok"></i>Experience designing and implementing secure networks, systems, and application architecture.</li>
                                            <li><i class="icon-ok"></i>Knowledge of risk assessment tools, technologies, and methods.</li>
                                            <li><i class="icon-ok"></i>Knowledge in 3rd party app integrations with security systems.</li>
                                            <li><i class="icon-ok"></i>Able to communicate technical security issues to peers and management.</li>
                                            <li><i class="icon-ok"></i>Able to work alone with minimum supervision/guidance and have strong time management and organizational skills.</li>
                                            <li><i class="icon-ok"></i>Have understanding of network and server security, security testing, software security.</li>
                                            <li><i class="icon-ok"></i>Experience in responding to multiple incidents at the same time.</li>
                                            <li><i class="icon-ok"></i>Had worked on a security operations center/cloud security operations setup is a plus.</li>
                                            <li><i class="icon-ok"></i>Experience implementing security systems on Mac OS or Mac devices is required.</li>
                                            <li><i class="icon-ok"></i>Experience implementing security systems in a remote or work-from-home environment.</li>
                                            <li><i class="icon-ok"></i>Strong analytical skills, and the desire to stay up to date on the latest technology.</li>
                                            <li><i class="icon-ok"></i>Excellent verbal and written English communication skills.</li>
                                            <li><i class="icon-ok"></i>Have experience working with a US team highly preferred.</li>
                                            <li><i class="icon-ok"></i>At Least 2 years experience</li>
                                            <li><i class="icon-ok"></i>Willing to work graveyard or in shifting schedules.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Nice to Haves
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Experience in using VirusTotal</li>
                                            <li><i class="icon-ok"></i>Experience in AWS</li>
                                            <li><i class="icon-ok"></i>Beauceron Security</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary.</li>
                                            <li><i class="icon-plus-sign"></i>Work-from-home setup as we prepare to provide a safe environment for our employees. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>We offer comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/IT-security-analyst/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>